import { navigationNamespace, mobileNamespace } from './symbols'

export enum ToastTypes {
	INFO = 'general',
	SUCCESS = 'success',
	FAILURE = 'failure',
	OFFLINE = 'offline',
}
export enum ToastResults {
	PRESSED = 'pressed',
	DISMISSED = 'dismissed',
}

type ToastResult = { result: ToastResults }

export type ShowAlertType = (
	title: string,
	body: string,
	actions: {
		positive: AlertAction
		negative?: AlertAction
		neutral?: AlertAction
	}
) => Promise<{ dismissed: boolean; key?: string }>

export type ShowToastNotification = (body: string, type?: ToastTypes, actionLabel?: string) => Promise<ToastResult>

export type AlertAction = {
	key: string
	label: string
	destructive?: string
}

type NativeMobileWixCodeNavigationApi = {
	dismissAllModals: () => void
	openURL: (url: string) => Promise<void>
	toScreen: (slug: string) => Promise<any>
	closeScreen: (data: any) => Promise<any>
}

type NativeMobileWixCodeSystemUiUserApi = {
	showAlert: ShowAlertType
	appFramework: {
		showToastNotification: ShowToastNotification
		ToastTypes: typeof ToastTypes
		ToastResults: typeof ToastResults
	}
}

type NativeMobileWixCodeSystemUiInternalApi = {
	showAlert: ShowAlertType
	appFramework: {
		showToastNotification: ShowToastNotification
	}
}

// User Interface Type
export type NativeMobileWixCodeSdkWixCodeApi = {
	[navigationNamespace]: NativeMobileWixCodeNavigationApi
	[mobileNamespace]: NativeMobileWixCodeSystemUiUserApi
}

// Internal Handlers Type
export type NativeMobileWixCodeSdkHandlers = {
	navigation: NativeMobileWixCodeNavigationApi
	systemUi: NativeMobileWixCodeSystemUiInternalApi
}

/**
 * Site feature config is calculated in SSR when creating the `viewerModel`
 * The config is available to your feature by injecting `named(PageFeatureConfigSymbol, name)`
 */
export type NativeMobileWixCodeSdkSiteConfig = {}

export type NativeMobileApis = Partial<{
	navigation: NativeMobileWixCodeNavigationApi
	systemUi: NativeMobileWixCodeSystemUiInternalApi
}>
